import React from 'react';

import Layout from '../components/layout';

class Privacy extends React.Component {
  render() {
    return (
      <Layout>
        Privacy policy to be written
        <br />
        tldr we don't really care about your data we just want to know how many people have used the site so we can boost our egos. 
      </Layout>
    );
  }
}

export default Privacy;
